.registration-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/images/loginBG.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    
    input {
        font-family: "bebas";
        outline: none;
        width: 86%;
        padding-left: 2%;
        padding-right: 2%;
        border: none;
        background-color: transparent;
        border-bottom: 2px solid white;
        letter-spacing: 1px;
        font-size: 22px;
        color: white;
    }

    label {
        font-family: "bebas";
        width: 90%;
        font-size: 25px;
        letter-spacing: 1px;
    }

    button {
        padding: 12.5px 30px;
        border: 0;
        border-radius: 100px;
        background-color: white;
        color: #152238;
        font-weight: Bold;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        font-family: "Bebas";
        font-size: 25px;
        letter-spacing: 1px;
    }

    button:hover {
        background-color: #152238;
        box-shadow: 0 0 20px #6fc5ff50;
        transform: scale(1.1);
        color: white;
    }
}

.registration-form {
    position: relative;
    width: 95%;
    height: 95%;
    background-color: #152238;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 15px 15px 10px 5px rgba(0, 0, 0, 0.5);

    h1 {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Pursuit";
        font-size: 40px;
        text-decoration: underline;
    }

    form {
        width: 100%;
        height: 90%;
        display: flex;
    }

    .login-img {
        position: absolute;
        top: 20px;
        left: 20px;
        transition: 0.2s ease;
        cursor: pointer;
        width: 50px;
        height: 50px;

        &:hover {
            transform: scale(1.1);
            transition: 0.2s ease;
        }
    }

    .home-img {
        position: absolute;
        top: 20px;
        right: 20px;
        transition: 0.2s ease;
        cursor: pointer;
        width: 50px;
        height: 50px;

        &:hover {
            transform: scale(1.1);
            transition: 0.2s ease;
        }
    }
}

.form-ls {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 40px);
    padding-bottom: 20px;
    padding-top: 20px;
    border-right: 0.5px dashed white;
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.form-rs {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 40px);
    padding-bottom: 20px;
    padding-top: 20px;
    border-left: 0.5px dashed white;
}

.bday-inputs {
    display: flex;
    justify-content: space-between;
    width: 90%;

    input {
        width: 20%;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.form-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "bebas";
    font-size: 20px;
    letter-spacing: 2px;
}

.waiver-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        width: 90%;
        font-size: 20px;
        font-family: "bebas";
        letter-spacing: 1px;
    }
}

@media screen and (max-width: 1050px) {
    .registration-page {
        width: 100%;
        height: 350vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../../assets/images/loginBG.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        
        input {
            font-family: "bebas";
            outline: none;
            width: 86%;
            padding-left: 2%;
            padding-right: 2%;
            border: none;
            background-color: transparent;
            border-bottom: 2px solid white;
            letter-spacing: 1px;
            font-size: 22px;
            color: white;
            border-radius: 0;
        }
    
        label {
            font-family: "bebas";
            width: 90%;
            font-size: 25px;
            letter-spacing: 1px;
        }
    
        button {
            padding: 12.5px 30px;
            border: 0;
            border-radius: 100px;
            background-color: white;
            color: #152238;
            font-weight: Bold;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            font-family: "Bebas";
            font-size: 25px;
            letter-spacing: 1px;
        }
    
        button:hover {
            background-color: #152238;
            box-shadow: 0 0 20px #6fc5ff50;
            transform: scale(1.1);
            color: white;
        }
    }
    
    .registration-form {
        position: relative;
        width: 95%;
        height: 95%;
        background-color: #152238;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: 15px 15px 10px 5px rgba(0, 0, 0, 0.5);
    
        h1 {
            width: 95%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-family: "Pursuit";
            font-size: 4vh;
            text-decoration: underline;
            text-align: center;
        }
    
        form {
            width: 100%;
            height: 90%;
            display: flex;
            flex-direction: column;
        }
    
        .login-img {
            position: absolute;
            top: 20px;
            left: 20px;
            transition: 0.2s ease;
            cursor: pointer;
            width: 50px;
            height: 50px;
    
            &:hover {
                transform: scale(1.1);
                transition: 0.2s ease;
            }
        }
    
        .home-img {
            position: absolute;
            top: 20px;
            right: 20px;
            transition: 0.2s ease;
            cursor: pointer;
            width: 50px;
            height: 50px;
    
            &:hover {
                transform: scale(1.1);
                transition: 0.2s ease;
            }
        }
    }
    
    .form-ls {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        height: calc(100% - 40px);
        padding-bottom: 20px;
        padding-top: 20px;
        border: none;
    }
    
    .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    
    .form-rs {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        height: calc(100% - 40px);
        padding-bottom: 20px;
        padding-top: 20px;
        border: none;
    }
    
    .bday-inputs {
        display: flex;
        justify-content: space-between;
        width: 90%;
    
        input {
            width: 20%;
    
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    
    .form-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "bebas";
        font-size: 20px;
        letter-spacing: 2px;
        margin-top: 50px;
    }
    
    .waiver-cont {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    
        p {
            width: 90%;
            font-size: 20px;
            font-family: "bebas";
            letter-spacing: 1px;
        }
    }
}