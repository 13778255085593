.login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/images/loginBG.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-console {
    background-color: #152238;
    width: 450px;
    height: 75vh;
    border: 10px solid #152238;
    border-radius: 20px;
    overflow: hidden;
}

.login-title {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    font-family: "pursuit";
    text-align: center;
}

.login-logo {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 70%;
        width: auto;
    }
}

.login-form {
    width: 100%;
    height: 45%;
    background-color: white;
    border-radius: 10px;

    form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    input {
        font-size: 25px;
        font-family: "bebas";
        width: 80%;
        border-bottom: 2px solid #152238;
        color: #152238;
        border-radius: 0px;
    }

    button {
        padding: 6px 15px;
        border: 0;
        border-radius: 100px;
        background-color: #152238;
        color: white;
        font-weight: Bold;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        font-family: "Bebas";
        font-size: 25px;
        width: 80%;
        border: 2px solid #152238;
        letter-spacing: 1px;
    }

    button:hover {
        background-color: white;
        box-shadow: 0 0 20px #6fc5ff50;
        color: #152238;
        border: 2px solid #152238;
    }
}

.login-footer {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;

    a {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: #6fc5ff;
        }
    }

    p {
        font-family: "Bebas";
        letter-spacing: 1px;
        font-size: 25px;
        text-align: center;
    }
}

@media screen and (max-width: 800px) {
    .login-page {
        width: 100%;
        height: 90vh;
        padding-bottom: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../../assets/images/loginBG.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .login-console {
        width: 90%;
    }

    .login-form {
        height: 40%;
        margin-bottom: 5%;
    }
}