.landing-page {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.landing-nav {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 8vh;
    width: 100%;
    background-color: #152238;
    display: flex;
    z-index: 99;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
}

.nav-logo {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav-logo-img {
    margin-left: 50px;
    height: 80%;
    width: auto;
}

.nav-opts {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    font-family: "Bebas";
}

.nav-opt {
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: #152238 2px solid;
    letter-spacing: 2px;
    font-size: 22px;
}

.nav-opt:hover {
    box-sizing: border-box;
    border-bottom: 2px solid white;
}


.nav-actns {
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
        padding: 6px 15px;
        border: 0;
        border-radius: 100px;
        background-color: white;
        color: #152238;
        font-weight: Bold;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        font-family: "Bebas";
        font-size: 25px;
    }

    button:hover {
        background-color: #152238;
        box-shadow: 0 0 20px #6fc5ff50;
        color: white;
    }
}

.mobile-landing-nav {
    display: none;
}

.my-bookings-cont {
    position: fixed;
    top: 10vh;
    right: 50px;
    width: 300px;
    height: 500px;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 9999;
}

.my-bookings-cont.active {
    display: inline-block;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.my-bookings-header {
    height: 10%;
    width: 100%;
    background-color: #152238;
    position: relative;
    box-sizing: border-box;
    border-bottom: 2px solid white;

    img {
        height: 50%;
        width: auto;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    img:hover {
        transform: scale(1.1);
        transition: all 0.2s ease;
    }
}

.my-bookings-list {
    width: 100%;
    height: 90%;
    background-color: #152238;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.user-booking {
    margin-top: 10px;
    border-bottom: 2px solid white;
    height: 45%;
    font-family: "Bebas";
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    p {
        width: 90%;
        font-size: 20px;
        color: white;
    }
}

.user-booking-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        padding: 6px 15px;
        border: 0;
        border-radius: 100px;
        background-color: white;
        color: #152238;
        font-weight: Bold;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        font-family: "Bebas";
        font-size: 20px;
    }

    button:hover {
        background-color: #152238;
        box-shadow: 0 0 20px #6fc5ff50;
        color: white;
    }
}

@media screen and (max-width: 1000px) {
    .landing-nav {
        display: none;
    }

    .mobile-landing-nav {
        position: fixed;
        display: flex;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 8vh;
        background-color: #152238;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
        transition: height 0.3s ease;
        flex-direction: column;
        overflow: hidden;
        z-index: 1000;
    }

    .mobile-landing-nav.active {
        position: fixed;
        display: flex;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 48vh;
        background-color: #152238;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
        transition: height 0.3s ease;
    }

    .mobile-nav-header {
        height: 8vh;
        width: 100%;
        display: flex;
        position: relative;
    }

    .mobile-nav-icon {
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .mobile-nav-icon-img {
        height: 50%;
        width: auto;
        margin-left: 10px;
        cursor: pointer;
        object-fit: contain;
    }

    .mobile-nav-logo {
        height: 100%;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-nav-logo-img {
        height: 70%;
        width: auto;
        margin-right: 10px;
    }


    .mobile-nav-body {
        height: 68vh;
        width: 100%;
    }

    .mobile-nav-opt {
        height: 10vh;
        width: 100%;
    }

    .mobile-nav-opt-link {
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "bebas";
        font-size: 30px;
        color: white;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            transform: scale(1.05);
            transition: all 0.2s ease;
        }
    }
}