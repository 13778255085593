/* Admin Login */

.admin-login-page {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #152238;
}

.admin-login-console {
    width: 95%;
    height: 95%;
    background-color: white;
    border-radius: 15px;
}

.admin-console-header {
    width: 100%;
    height: 20%;
    padding-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #152238;
    font-family: "Pursuit";
    font-size: max(2.7vw, 3.7vh);
    text-align: center;
}

.admin-console-form {
    width: 100%;
    height: 70%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.admin-console-label {
    width: 100%;
    text-align: center;
    font-family: "Arial";
    font-size: max(2.5vw, 3.5vh);
    color: #152238;
}

.admin-console-input {
    font-size: max(2.5vw, 3.5vh);
    width: 80%;
    font-family: "Arial";
    color: #152238;
    border-radius: 15px;
    border: 2px solid #152238;
    padding: 10px;
}

.admin-console-button {
    font-size: max(2.5vw, 3.5vh);
    width: 60%;
    font-family: "Arial";
    color: #152238;
    border-radius: 15px;
    border: 2px solid #152238;
    padding: 10px;
    background-color: white;

    &:hover {
        background-color: #152238;
        color: white;
    }
}

/* Admin Page */

.admin-page {
    width: 100%;
    height: 100dvh;
    background-color: #152238;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-booking-type {
    width: 95%;
    height: 15%;
    background-color: lightblue;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.admin-booking-form {
    width: 95%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: lightblue;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.admin-form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}   

#booking-form {
    width: 98%;
    height: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-form-group {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 20px;
}

.admin-label {
    font-family: "Arial";
    font-size: 18px;
    color: #152238;
    width: 95%;
}

.admin-input {
    font-family: "Arial";
    font-size: 18px;
    color: #152238;
    padding: 5px;
    width: 95%;
}

.admin-select {
    font-family: "Arial";
    font-size: 18px;
    color: #152238;
    padding: 5px;
    width: 95%;
}

.admin-textarea {
    font-family: "Arial";
    font-size: 18px;
    color: #152238;
    padding: 5px;
    width: 95%;
}

.admin-booking-form-actns {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 20px;
}

.admin-button {
    font-family: "Arial";
    font-size: 18px;
    color: #152238;
    padding: 10px;
    border: 2px solid #152238;
    border-radius: 15px;

    &:hover {
        color: white;
        background-color: #152238;
    }
}