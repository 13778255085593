html,body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  display: none;
}

/* Basic CSS Reset for buttons, images, and text tags */

/* Remove default margins and paddings for text tags */
p, h1, h2, h3, h4, h5, h6, span, a {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

/* Remove default styling for buttons */
button {
  border: none;
  outline: none;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}

@font-face {
  font-family: "Pursuit";
  src: url("./assets/fonts/Pursuit.ttf");
}

@font-face {
  font-family: "Bebas";
  src: url("./assets/fonts/Bebas.ttf");
}