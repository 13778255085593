.booking-section {
    width: 100%;
    height: 92vh;
    padding-top: 8vh;
    position: absolute;
    top: 200vh;
    left: 0;
    background-image: url("../../../assets/images/bookingBG.png");
    background-size: cover;
    background-position: fixed;
    background-repeat: no-repeat;
}

.booking-title {
    width: 90%;
    margin-left: 10%;
    height: 20%;
    font-family: "Pursuit";
    font-size: 30px;
    color: white;
    display: flex;
    align-items: center;
}

.booking-body {
    width: 100%;
    height: 80%;
    display: flex;
}

.booking-ls {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    display: none;
}

.calendar-cont {
    width: 80%;
    height: 80%;
}

.calendar-month {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    background-color: #152238;
    box-sizing: border-box;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
    border-bottom: 2px solid white;
}

.calendar-arrow {
    width: 5%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    height: 50%;
    display: flex;
    cursor: pointer;
    transition: all 0.1s ease;

    &:hover {
        transform: scale(1.1);
        transition: all 0.1s ease;
    }
}

.calendar-month-text {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Bebas";
    color: white;
    letter-spacing: 1px;
}

.calendar-days {
    width: 100%;
    height: 80%;
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: auto;
    row-gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.calendar-day {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 35px;
        font-family: "Bebas";
        background-color: #152238;
        border: 2px solid white;
        width: 7vh;
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background-color: white;
            color: #152238;
            transition: all 0.2s ease;
        }
    }
}

.booking-rs {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pricing-cont {
    width: 80%;
    height: 80%;
    overflow: hidden;
    background-color: #152238;
    color: white;
    box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.5);
}

.pricing-title {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: "Bebas";
    letter-spacing: 1px;
}

.pricing-info {
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.pricing-info-title {
    font-family: "bebas" !important;
    height: 13% !important;
    font-size: 35px !important;
    display: flex !important;
    align-items: center !important;
    width: 90% !important;
    padding-left: 10% !important;
    border-top: 2px solid white !important;
    letter-spacing: 1px !important;
}

.pricing-info-text {
    width: 70% !important;
    padding-left: 15% !important;
    padding-right: 15% !important;
    height: 10% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-size: 30px !important;
    font-family: 'bebas' !important;
}

/* booking form */

.booking-info-cont {
    width: 80%;
    height: 80%;
    background-color: #152238;
    box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.5);

    form {
        width: 100%;
        height: 100%;
    }
}

.booking-inputs {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    color: white;
}

.booking-inputs-title {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: "Bebas";
    letter-spacing: 1px;
    border-bottom: 2px solid white;
}

.booking-inputs-info {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: "bebas";
    font-size: 30px;

    input {
        width: 90%;
        height: 8%;
        font-family: "bebas";
        border: none;
        outline: none;
        font-size: 25px;
    }

    label {
        width: 90%;
        height: 8%;
    }

    select {
        width: 90%;
        height: 8%;
        font-family: "bebas";
        border: none;
        outline: none;
        font-size: 25px;
    }
}

.booking-info-btns {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 2px solid white;

    button {
        padding: 12.5px 30px;
        border: 0;
        border-radius: 100px;
        background-color: white;
        color: #152238;
        font-weight: Bold;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        font-family: "Bebas";
        font-size: 25px;
    }

    button:hover {
        background-color: #152238;
        box-shadow: 0 0 20px #6fc5ff50;
        transform: scale(1.1);
        color: white;
    }
}

.courts-display-cont {
    width: 80%;
    height: 80%;
}

.courts-display-title {
    height: 15%;
    background-color: #152238;
    border-bottom: 2px solid white;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 40px;
    font-family: "Bebas";
    letter-spacing: 1px;
    color: white;

    h3 {
        width: 72%;
        padding-left: 14%;
        text-align: center;
    }

    img {
        height: 70%;
        width: 14%;
        object-fit: contain !important;
        filter: invert(100%);
    }
}

.courts-display {
    margin-top: 5%;
    height: 80%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);

    img {
        width: 11vw;
        cursor: pointer;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            position: absolute;
            color: white;
            font-family: 'bebas';
            letter-spacing: 1px;
            font-size: 50px;
            -webkit-text-stroke: 1px #152238;
            cursor: pointer;
        }
    }
}

.court-cont-1 {
    display: flex;
    align-items: flex-end !important;
    justify-content: flex-end !important;
}

.court-cont-2 {
    display: flex;
    align-items: flex-end !important;
    justify-content: flex-start !important;
}

.court-cont-3 {
    img {
        align-self: flex-end;
    }
}

.court-cont-4 {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-end !important;
}

.court-cont-5 {
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

.court-cont-6 {
    img {
        align-self: flex-start;
    }
}

.court-cont-7 {
    display: flex;
    align-items: center !important;
    justify-content: flex-end !important;
}

.court-cont-8 {
    display: flex;
    align-items: center !important;
    justify-content: flex-start !important;
}

.rates-section {
    display: none;
}

.calendar-section {
    display: none;
}

.court-cont-1.active,
.court-cont-2.active,
.court-cont-3.active,
.court-cont-4.active,
.court-cont-5.active,
.court-cont-6.active,
.court-cont-7.active,
.court-cont-8.active,
.court-cont-9.active,
.court-cont-10.active,
.court-cont-11.active,
.court-cont-12.active {
    img {
        box-sizing: border-box;
        border: 3px solid #152238;
    }
}

.loading-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #152238;
    font-size: 25px;
    font-family: "Bebas";
}

@media screen and (max-width: 1000px) {
    .booking-section {
        display: none;
    }

    .rates-section {
        display: flex;
        width: 100%;
        height: 88vh;
        padding-top: 14vh;
        position: absolute;
        top: 372vh;
        left: 0;
        background-image: url("../../../assets/images/calendarBG.png");
        background-size: cover;
        background-position: fixed;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: center;
    }

    .pricing-info-title {
        font-size: 3vh !important;
    }

    .pricing-info-text {
        font-size: max(3vw, 16px) !important;
    }

    .calendar-section {
        background-color: #152238;
        display: flex;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 474vh;
        background-image: url("../../../assets/images/bookingBG.png");
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        display: none;
    }

    .calendar-cont {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
            color: white;
            font-family: "Pursuit";
            font-size: min(60px, 10vw);
            text-align: center;
            width: 100%;
            height: 15%;
        }
    }

    .calendar-month {
        width: 90%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8vw;
        background-color: #152238;
        box-sizing: border-box;
        box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
        border-bottom: 2px solid white;

        img {
            width: 5%;
            margin-right: 2.5%;
            margin-left: 2.5%;
            height: 50%;
            display: flex;
            cursor: pointer;
            transition: all 0.1s ease;

            &:hover {
                transform: scale(1.1);
                transition: all 0.1s ease;
            }
        }

        h3 {
            width: 80%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Bebas";
            color: white;
            letter-spacing: 1px;
        }
    }

    .calendar-days {
        width: 95%;
        height: 45%;
        margin-top: 5%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: auto;
        row-gap: 10px;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .calendar-day {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 3vh;
            font-family: "Bebas";
            background-color: #152238;
            border: 2px solid white;
            width: 5vh;
            height: 5vh;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                background-color: white;
                color: #152238;
                transition: all 0.2s ease;
            }
        }
    }

    .booking-info-cont {
        width: 90%;
        height: 70%;
        background-color: #152238;
        box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.5);

        form {
            width: 100%;
            height: 100%;
        }
    }

    .booking-inputs {
        width: 100%;
        height: 85%;
        display: flex;
        flex-direction: column;
        color: white;
    }

    .booking-inputs-title {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-family: "Bebas";
        letter-spacing: 1px;
        border-bottom: 2px solid white;
    }

    .booking-inputs-info {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-family: "bebas";
        font-size: 25px;

        input {
            width: 85%;
            height: 5%;
            font-family: "bebas";
            border: none;
            outline: none;
            font-size: 25px;
        }

        label {
            width: 90%;
            height: 8%;
        }

        select {
            width: 90%;
            height: 8%;
            font-family: "bebas";
            border: none;
            outline: none;
            font-size: 25px;
        }

        .css-b62m3t-container {
            height: 10%;
            width: 90%;
            color: black;
            font-family: "bebas";
            font-size: 25px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .booking-info-btns {
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-top: 2px solid white;

        button {
            padding: 12.5px 30px;
            border: 0;
            border-radius: 100px;
            background-color: white;
            color: #152238;
            font-weight: Bold;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            font-family: "Bebas";
            font-size: 25px;
        }

        button:hover {
            background-color: #152238;
            box-shadow: 0 0 20px #6fc5ff50;
            transform: scale(1.1);
            color: white;
        }
    }
}